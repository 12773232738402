.header-container {
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  height: 46px;
  top: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid #e4e4e4;
  background-color: #fff;
}

.ant-menu-horizontal {
  border-bottom: none !important;
}

.fixed-header {
  flex-grow: 1;
}

.right-aligned-section {
  display: flex;
  align-items: center;
  margin-right: 0px; /* Add some margin for better appearance */
}

.logo-placeholder,
.settings-placeholder {
  display: flex;
  align-items: center;
  margin: 0 10px; /* Add some margin between the logo and settings */
}

.left-aligned-section {
  display: flex;
  align-items: center; /* Align vertically */
}

/* New CSS classes for logo */
.header-logo {
  margin-left: 20px;
}

.logo-image {
  height: 30px;
  vertical-align: top;
}

.ant-menu {
  min-width: 300px !important;
}
