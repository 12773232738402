body {
  font-family: 'Inter', sans-serif;
  background-color: #f8f8f8;
  margin: 0;
  padding: 0;
  color: #333;
  line-height: 1.5;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* This will make the .App container take up the full viewport height */
}

h1 {
  margin: auto;
  display: block;
  text-align: center;
  color: #000;
  font-size: 32px;
  font-family: 'Inter', sans-serif;
  font-weight: 900;
  /* border-bottom: 2px solid #f2f2f2; */
  padding-bottom: 4px;
  margin-bottom: 20px;
}

.App > * + * {
  margin-top: 32px;
  transition: margin-top 0.5s; /* Ensure the transition effect is applied during hiding */
}

/* Error Message Styles */
.error-message {
  color: #e74c3c;
  background-color: #f9dcdc;
  padding: 10px;
  border: 1px solid #e74c3c;
  border-radius: 5px;
}

.header-file-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-file-name span {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #999 !important;
  display: block;
  margin-bottom: 4px;
}

.header-file-name h1 {
  font-family: 'Inter', sans-serif;
  margin-top: 0px;
  margin-bottom: 20px; /* default margin-bottom */
}

.header-file-name h1:last-child,
.header-file-name h1:not(:last-child) {
  margin-bottom: 40px !important; /* margin-bottom when there is no p */
}

.header-file-name p {
  font-size: 14px;
  max-width: 600px;
  text-align: center;
  margin-bottom: 40px !important;
}
