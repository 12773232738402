.contact-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  min-width: 90%;
  h1 {
    text-align: left;
  }
  .contact-right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-card  {
      width: 80% !important;
    }
  }
}