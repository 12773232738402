/* src/components/ProfilePage.css */

.master-card {
    display: flex;
    justify-content: space-between;
  }
  
  .profile-container {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-wrap: nowrap; /* Prevent cards from wrapping onto new lines */
  }
  
  .profile-card {
    width: 400px;
    height: 600px;
    flex-shrink: 0; /* Prevent card from shrinking if container width is too small */
  }
  
  .profile-avatar {
    display: flex;
    justify-content: center;
  }
  
  .profile-title {
    text-align: center;
  }
  
  .explorer-card {
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    width: 400px;
    height: 600px;
    flex-shrink: 0; /* Prevent card from shrinking if container width is too small */
  }
  
  .explorer-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .explorer-title {
    text-align: center;
  }

  .cards-container {
    background-color: white;
    border-radius: 10px;
    /* Add padding if needed */
    padding: 20px;
  }